import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton, SecondaryButton, PrimaryButton, ExternalLink } from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import config from '../../config';
const { Money } = sdkTypes;
const Moment = require('moment');
import { FaMoneyBillAlt, FaUserTie } from 'react-icons/fa';
import { FiClock } from 'react-icons/fi';

import { SiSuperuser } from 'react-icons/si';
import { GrSteps, GrMoney } from 'react-icons/gr';
import { MdOutlineCategory, MdPeopleOutline } from 'react-icons/md';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';
import { IoMdResize } from 'react-icons/io';

import {
  GiOfficeChair,
  GiSandsOfTime,
  GiReceiveMoney,
  GiFactory,
  GiPayMoney,
} from 'react-icons/gi';
import { BiBuildingHouse } from 'react-icons/bi';
import { BsCalendarMonth, BsClock } from 'react-icons/bs';

import css from './ListingPage.module.css';

const categoryKeys = [
  { key: config.REQUIREMENT, value: 'REQUIREMENT' },
  { key: config.INVEST, value: 'INVEST' },
  { key: config.LISTING, value: 'LISTING' },
  { key: config.SHARE, value: 'SHARE' },
];
const enquireButtonText = 'Enquire';

const SectionHeading = props => {
  const {
    richTitle,
    intl,
    showContactUser,
    onContactUser,
    showDesktopEnquireButton,
    publicData,
  } = props;

  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        <div className={css.headingLeft}>
          <h1 className={css.title}>{richTitle}</h1>
          {publicData.website ? (
            <ExternalLink
              className={css.subtitle}
              href={publicData.website}
              title={publicData.website}
            >
              Website
            </ExternalLink>
          ) : null}
        </div>
        {/* <div className={css.headingRight}>
          {showContactUser && showDesktopEnquireButton && (
            <div className={css.enquireButtonContainer}>
              <p className={css.smallPrint}>Contact the listing author</p>
              <PrimaryButton onClick={onContactUser} enforcePagePreloadFor="SignupPage">
                {enquireButtonText}
              </PrimaryButton>

            </div>
          )}
        </div> */}
      </div>
      <div className={showContactUser ? css.topIconBar : css.topIconBarFull}>
        {publicData.category ? (
          <div className={css.topIconSectionEach}>
            <div className={css.heading}>
              <MdOutlineCategory width={'2em'} className={css.icon} />

              <h4>Category</h4>
            </div>
            <div className={css.content}>
              <p>{categoryKeys.find(x => x.key == publicData.category)?.value}</p>
            </div>
          </div>
        ) : null}
        {publicData.stage ? (
          <div className={css.topIconSectionEach}>
            <div className={css.heading}>
              <GiOfficeChair className={css.icon} />

              <h4>Stage</h4>
            </div>
            <div className={css.content}>
              <p>
                {config.custom.companyInfoStage?.find(item => item.key === publicData.stage)?.label}
              </p>
            </div>
          </div>
        ) : null}
        {publicData.sizeOfSpace ? (
          <div className={css.topIconSectionEach}>
            <div className={css.heading}>
              <IoMdResize className={css.icon} />

              <h4>Size of space in m2</h4>
            </div>
            <div className={css.content}>
              <p>{publicData.sizeOfSpace / 10000000}</p>
            </div>
          </div>
        ) : null}
        {publicData.capacityOfSpace ? (
          <div className={css.topIconSectionEach}>
            <div className={css.heading}>
              <MdPeopleOutline height={'23px'} width={'23px'} className={css.icon} />

              <h4>Capacity of Space</h4>
            </div>
            <div className={css.content}>
              <p>{publicData.capacityOfSpace / 10000000}</p>
            </div>
          </div>
        ) : null}
        {/* {publicData.spaceType ? (
          <div className={css.topIconSectionEach}>
            <div className={css.heading}>
              <HiOutlineOfficeBuilding className={css.icon} />

              <h4>Type of Space</h4>
            </div>
            <div className={css.content}>
              <p>
                {config?.custom?.spaceTypes?.find(i => i?.value == publicData.spaceType)?.label}
              </p>
            </div>
          </div>
        ) : null} */}
        {publicData.length ? (
          <div className={css.topIconSectionEach}>
            <div className={css.heading}>
              <BsCalendarMonth className={css.icon} />

              <h4>Length</h4>
            </div>
            <div className={css.content}>
              <p>{config?.custom?.lengthTypes?.find(i => i.value == publicData.length)?.label}</p>
            </div>
          </div>
        ) : null}
        {/* {publicData.usage ? (
          <div className={css.topIconSectionEach}>
            <div className={css.heading}>
              <BiBuildingHouse className={css.icon} />

              <h4>Usage</h4>
            </div>
            <div className={css.content}>
              <p>{config?.custom?.usage?.find(i => i?.value == publicData.usage)?.label}</p>
            </div>
          </div>
        ) : null} */}
        {publicData.yearsOfExperience ? (
          <div className={css.topIconSectionEach}>
            <div className={css.heading}>
              <BsCalendarMonth className={css.icon} />

              <h4>Years Of Experience</h4>
            </div>
            <div className={css.content}>
              <p>{publicData.yearsOfExperience}</p>
            </div>
          </div>
        ) : null}

        {publicData.companyWorth ? (
          <div className={css.topIconSectionEach}>
            <div className={css.heading}>
              <FaMoneyBillAlt className={css.icon} />

              <h4>Company Worth</h4>
            </div>
            <div className={css.content}>
              <p>{formatMoney(intl, new Money(publicData.companyWorth, config.currency))}</p>
            </div>
          </div>
        ) : null}
        {publicData.grossDividend ? (
          <div className={css.topIconSectionEach}>
            <div className={css.heading}>
              <FaMoneyBillAlt className={css.icon} />

              <h4>Gross Dividend </h4>
            </div>
            <div className={css.content}>
              <p>{formatMoney(intl, new Money(publicData.grossDividend, config.currency))}</p>
            </div>
          </div>
        ) : null}
        {publicData.idealInvestorRole ? (
          <div className={css.topIconSectionEach}>
            <div className={css.heading}>
              <SiSuperuser className={css.icon} />

              <h4>Ideal Investor Role</h4>
            </div>
            <div className={css.content}>
              <p>
                {
                  config.custom.IdealInvestorRole?.find(
                    item => item.key === publicData.idealInvestorRole
                  )?.label
                }
              </p>
            </div>
          </div>
        ) : null}
        {publicData.requestedTiming &&
        publicData.requestedTiming?.startDate &&
        publicData.requestedTiming.endDate ? (
          <div className={css.topIconSectionEach}>
            <div className={css.heading}>
              <BsClock className={css.icon} />

              <h4>Requested Timing</h4>
            </div>
            <div className={css.content}>
              <p>
                {Moment(publicData.requestedTiming.startDate).format('M/D/YYYY, hh:mm ')} {' - '}
                {Moment(publicData.requestedTiming.endDate).format('M/D/YYYY, hh:mm ')}
              </p>
            </div>
          </div>
        ) : null}
        {publicData.idealInvestor ? (
          <div className={css.topIconSectionEach}>
            <div className={css.heading}>
              <FaUserTie className={css.icon} />

              <h4>Ideal Investor</h4>
            </div>
            <div className={css.content}>
              <p>
                {
                  config.custom.companyInfoIdeal.find(item => item.key === publicData.idealInvestor)
                    ?.label
                }
              </p>
            </div>
          </div>
        ) : null}
        {publicData.minimumInvestment ? (
          <div className={css.topIconSectionEach}>
            <div className={css.heading}>
              <GiReceiveMoney className={css.icon} />

              <h4>Minimum Investment</h4>
            </div>
            <div className={css.content}>
              <p>{formatMoney(intl, new Money(publicData.minimumInvestment, config.currency))}</p>
            </div>
          </div>
        ) : null}
        {publicData.previousRound ? (
          <div className={css.topIconSectionEach}>
            <div className={css.heading}>
              <GrMoney className={css.icon} />

              <h4>Previous Round</h4>
            </div>
            <div className={css.content}>
              <p>{formatMoney(intl, new Money(publicData.previousRound, config.currency))}</p>
            </div>
          </div>
        ) : null}
        {publicData.raisedAmountTotal ? (
          <div className={css.topIconSectionEach}>
            <div className={css.heading}>
              <GiPayMoney className={css.icon} />

              <h4>Raised Amount</h4>
            </div>
            <div className={css.content}>
              <p>{formatMoney(intl, new Money(publicData.raisedAmountTotal, config.currency))}</p>
            </div>
          </div>
        ) : null}
        {publicData.raisingAmountTotal ? (
          <div className={css.topIconSectionEach}>
            <div className={css.heading}>
              <FaMoneyBillAlt className={css.icon} />

              <h4>Raising Amount</h4>
            </div>
            <div className={css.content}>
              <p>{formatMoney(intl, new Money(publicData.raisingAmountTotal, config.currency))}</p>
            </div>
          </div>
        ) : null}
        {publicData.industry ? (
          <div className={css.topIconSectionEach}>
            <div className={css.heading}>
              <GiFactory className={css.icon} />

              <h4>Industry</h4>
            </div>
            <div className={css.content}>
              <p>
                {config.custom.industry?.find(item => item.value == publicData.industry)?.label}
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SectionHeading;

import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import ReactReadMoreReadLess from 'react-read-more-read-less';
import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDescriptionMaybe = props => {
  const { description } = props;
  return description ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.descriptionTitle" />
      </h2>
      <p className={css.description}>
        <ReactReadMoreReadLess
          charLimit={200}
          readMoreText={'Read more ▼'}
          readLessText={'Read less ▲'}
          readMoreClassName={css.readMore}
          readLessClassName={css.readLess}
        >
          {description}
        </ReactReadMoreReadLess>
      </p>
    </div>
  ) : null;
};

export default SectionDescriptionMaybe;

import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import config from '../../config';

import css from './ListingPage.module.css';
import SectionPitchMaybe from './SectionPitchMaybe';

function SectionCompanyInfo(props) {
  const { publicData } = props;
  const { investIndustry, stage, idealInvestor } = publicData;

  //section industry
  const industries = investIndustry?.map(
    item => config.custom.companyInfoIndustries?.find(i => i.key === item)?.label
  );
  const sectionIndustry = investIndustry ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.industryTitle" />
      </h2>
      {industries?.map(item => (
        <li className={css.description}>{item}</li>
      ))}
    </div>
  ) : null;
  //section Stage
  const sectionStage = stage ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.stageTitle" />
      </h2>
      <p className={css.description}>
        {config.custom.companyInfoStage?.find(item => item.key === stage)?.label}
      </p>
    </div>
  ) : null;
  //section idealInvestor
  const sectionIdealInvestor = idealInvestor ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.idealInvestorTitle" />
      </h2>
      <p className={css.description}>
        {config.custom.companyInfoIdeal?.find(item => item.key === idealInvestor)?.label}
      </p>
    </div>
  ) : null;
  return (
    <div>
      {/* {sectionIndustry}
      {sectionStage}
      {sectionIdealInvestor} */}
      <SectionPitchMaybe publicData={publicData} />
    </div>
  );
}

export default SectionCompanyInfo;

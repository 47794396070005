import React from 'react';
import _ from 'lodash';
import css from './ListingPage.module.css';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import Collapse from 'rc-collapse';

const Panel = Collapse.Panel;

const days = {
  mon: 'ListingPage.AvailabilityTable.mondayLabel',
  tue: 'ListingPage.AvailabilityTable.tuesdayLabel',
  wed: 'ListingPage.AvailabilityTable.wednesdayLabel',
  thu: 'ListingPage.AvailabilityTable.thursdayLabel',
  fri: 'ListingPage.AvailabilityTable.fridayLabel',
  sat: 'ListingPage.AvailabilityTable.saturdayLabel',
  sun: 'ListingPage.AvailabilityTable.sundayLabel',
};

const getFullDayName = key => days[key];

const SectionAvailability = props => {
  const { publicData, intl } = props;
  const { general_availability: entries } = publicData;

  if (!entries) return null;

  let data = [];

  const dayOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

  data = _.groupBy(entries, 'dayOfWeek');

  data = dayOfWeek.map(d => {
    if (data[d]) {
      const t = Array.isArray(data[d])
        ? data[d].map(c => ({ startTime: c.startTime, endTime: c.endTime }))
        : [{ startTime: data[d].startTime, endTime: data[d].endTime }];

      return {
        dayOfWeek: d,
        times: t,
      };
    }
    return { dayOfWeek: d, not_available: true };
  });

  const dayOfWeekTitle = intl.formatMessage({ id: 'ListingPage.AvailabilityTable.dayOfWeekTitle' });
  const availabilityTitle = intl.formatMessage({
    id: 'ListingPage.AvailabilityTable.availabilityTitle',
  });

  const formatToAMPM = time => {
    let pieces = time.split(':');
    let hours = +pieces[0];
    let minutes = +pieces[1];
    let am;
    // console.log(hours);
    if (hours == 0) {
      am = true;
      hours = 12;
    } else if (hours > 12) {
      am = false;
      hours = hours - 12;
    } else {
      am = true;
    }

    // console.log({ time, pieces, hours, minutes, am });

    const timeString = `${hours < 10 ? '0' + hours : hours}:${
      minutes < 10 ? '0' + minutes : minutes
    } ${am ? 'AM' : 'PM'}`;

    return timeString;
  };

  const header = intl.formatMessage({ id: 'ListingPage.AvailabilityTable.accordionTitle' });

  return (
    <div className={css.tableContainer} id="availability">
      <h2 className={css.participateTitle} style={{ color: 'var(--matterColor)' }}>
        <FormattedMessage id="ListingPage.availabilityTableTitle" />
      </h2>
      <Collapse accordion={false}>
        <Panel header={header}>
          <div className={css.pricingTableWrapper}>
            <table className={css.table}>
              <thead>
                <tr className={css.availabilityTableHeader}>
                  <th>{dayOfWeekTitle}</th>
                  <th>{availabilityTitle}</th>
                </tr>
              </thead>
              <tbody>
                {data.map(d => (
                  <tr
                    key={JSON.stringify(d)}
                    // className={`${d.not_available ? css.not_available : css.available}`}
                  >
                    <td className={css.availabililityTableDays}>
                      {intl.formatMessage({ id: getFullDayName(d.dayOfWeek) })}
                    </td>
                    {d.not_available ? (
                      <td className={css.not_available}>N/A</td>
                    ) : (
                      <td>
                        <ul>
                          {d.times?.map(e => (
                            <li key={JSON.stringify(e)}>
                              {formatToAMPM(e.startTime)} - {formatToAMPM(e.endTime)}
                            </li>
                          ))}
                        </ul>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default injectIntl(SectionAvailability);

import React from 'react';
import { injectIntl } from '../../util/reactIntl';
import { FormattedMessage } from 'react-intl/dist/react-intl';
import css from './ListingPage.module.css';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import config from '../../config';
const { Money } = sdkTypes;
function SectionPitchMaybe(props) {
  const { publicData, intl } = props;
  const {
    minimumInvestment,
    previousRound,
    raisedAmountTotal,
    raisingAmountTotal,
    companyWorth,
    grossDividend,
  } = publicData;
  const sectionCompanyWorth = companyWorth ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.companyWorthTitle" />
      </h2>
      <p className={css.description}>
        {formatMoney(intl, new Money(companyWorth, config.currency))}
      </p>
    </div>
  ) : null;
  const sectionGrossDividend = grossDividend ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.grossDividendTitle" />
      </h2>
      <p className={css.description}>
        {formatMoney(intl, new Money(grossDividend, config.currency))}
      </p>
    </div>
  ) : null;
  const sectionMinimumInvestment = minimumInvestment ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.minimumInvestmentTitle" />
      </h2>
      <p className={css.description}>
        {formatMoney(intl, new Money(minimumInvestment, config.currency))}
      </p>
    </div>
  ) : null;
  const sectionPreviousRound = previousRound ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.previousRoundTitle" />
      </h2>
      <p className={css.description}>
        {formatMoney(intl, new Money(previousRound, config.currency))}
      </p>
    </div>
  ) : null;
  const sectionRaisedAmountTotal = raisedAmountTotal ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.raisedAmountTotalTitle" />
      </h2>
      <p className={css.description}>
        {formatMoney(intl, new Money(raisedAmountTotal, config.currency))}
      </p>
    </div>
  ) : null;
  const sectionRaisingAmountTotal = raisingAmountTotal ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.raisingAmountTotalTitle" />
      </h2>
      <p className={css.description}>
        {formatMoney(intl, new Money(raisingAmountTotal, config.currency))}
      </p>
    </div>
  ) : null;
  return (
    <div>
      {/* {sectionCompanyWorth}
      {sectionGrossDividend}
      {sectionMinimumInvestment}
      {sectionPreviousRound}
      {sectionRaisedAmountTotal}
      {sectionRaisingAmountTotal} */}
    </div>
  );
}

export default injectIntl(SectionPitchMaybe);

import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import config from '../../config';

import css from './ListingPage.module.css';
import { PropertyGroup } from '../../components';

const SectionDetailsMaybe = props => {
  const { publicData } = props;

  const {
    spaceType,
    sizeOfSpace,
    capacityOfSpace,
    usage,
    length,
    // openingHours,
    otherUsage,
    amenties,
    otherSpaceType,
    otherAmenity,
  } = publicData;
  const convertFrom24To12Format = time24 => {
    const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
    const period = +sHours < 12 ? 'AM' : 'PM';
    const hours = +sHours % 12 || 12;

    return `${hours}:${minutes} ${period}`;
  };
  const isOtherSpaceType = spaceType?.includes('other');

  let selectedSpaceType = Array.isArray(spaceType)
    ? spaceType?.length > 0
      ? config.custom.spaceTypes?.filter(o => spaceType?.find(s => s === o.key))
      : []
    : null;
  selectedSpaceType = isOtherSpaceType
    ? [...selectedSpaceType, { key: otherSpaceType, label: otherSpaceType }]?.filter(
        i => i.key !== 'other'
      )
    : selectedSpaceType;
  const typeOfSpaceSelected = spaceType?.includes('other')
    ? [...spaceType, otherSpaceType]
    : spaceType;
  const sectionSpaceType =
    selectedSpaceType?.length > 0 ? (
      <div className={css.sectionDescription}>
        <h2 className={css.descriptionTitle}>Space Types</h2>

        <PropertyGroup
          id="ListingPage.spaceType"
          options={selectedSpaceType}
          selectedOptions={typeOfSpaceSelected}
          twoColumns={selectedSpaceType?.length > 1}
        />
      </div>
    ) : null;
  //section size of space
  const sectionSizeOfSpace = sizeOfSpace ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.sizeOfSpaceTitle" />
      </h2>
      <p className={css.description}>{sizeOfSpace / 10000000}</p>
    </div>
  ) : null;

  //section capacity
  const sectionCapacityOfSpace = capacityOfSpace ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.capacityTitle" />
      </h2>
      <p className={css.description}>{capacityOfSpace / 10000000}</p>
    </div>
  ) : null;

  //section opening hours
  // const sectionOpeningHours = openingHours ? (
  //   <div className={css.sectionDescription}>
  //     <h2 className={css.descriptionTitle}>
  //       <FormattedMessage id="ListingPage.openingHoursTitle" />
  //     </h2>
  //     <ol type="1" className={css.orderedList}>
  //       {openingHours?.map(item => (
  //         <li className={css.description}>
  //           {convertFrom24To12Format(item?.startHour)} - {convertFrom24To12Format(item?.endHour)}
  //         </li>
  //       ))}
  //     </ol>
  //   </div>
  // ) : null;
  const isOtherAmenity = amenties?.includes('other');
  let selectedAmenties = Array.isArray(amenties)
    ? amenties?.length > 0
      ? config.custom.amenties?.filter(o => amenties?.find(s => s === o.key))
      : []
    : null;
  selectedAmenties = isOtherAmenity
    ? [...selectedAmenties, { key: otherAmenity, label: otherAmenity }]?.filter(
        i => i.key !== 'other'
      )
    : selectedAmenties;
  const amentiesOptions = amenties?.includes('other') ? [...amenties, otherAmenity] : amenties;
  // const selectedAmenties = config.custom.amenties?.filter(o => amenties?.find(s => s === o.key));
  const sectionAmenties =
    amenties?.length > 0 ? (
      <div className={css.sectionDescription}>
        <h2 className={css.descriptionTitle}>
          <FormattedMessage id="ListingPage.amentiesTitle" />
        </h2>

        <PropertyGroup
          id="ListingPage.amenties"
          options={selectedAmenties}
          selectedOptions={amentiesOptions}
          twoColumnsVerySmall={selectedAmenties.length > 1}
        />
      </div>
    ) : null;
  //section usage
  const isOtherUsage = usage?.includes('other');
  let selectedUsage = Array.isArray(usage)
    ? usage?.length > 0
      ? config.custom.usage?.filter(o => usage?.find(s => s === o.key))
      : []
    : null;
  selectedUsage = isOtherUsage
    ? [...selectedUsage, { key: otherUsage, label: otherUsage }]?.filter(i => i.key !== 'other')
    : selectedUsage;
  const usageOptions = usage?.includes('other') ? [...usage, otherUsage] : usage;
  const sectionUsage =
    usage?.length > 0 ? (
      <div className={css.sectionDescription}>
        <h2 className={css.descriptionTitle}>
          <FormattedMessage id="ListingPage.usageTitle" />
        </h2>
        <PropertyGroup
          id="ListingPage.amenties"
          options={selectedUsage}
          selectedOptions={usageOptions}
          twoColumnsVerySmall={selectedAmenties.length > 1}
        />
        {/* <p className={css.description}>{usageLabel?.label}</p> */}
      </div>
    ) : null;

  //section length
  const sectionLength = () => {
    const lengthLabel = config?.custom?.lengthTypes?.filter(i => i?.value == length)[0]?.label;

    return length ? (
      <div className={css.sectionDescription}>
        <h2 className={css.descriptionTitle}>
          <FormattedMessage id="ListingPage.lengthTitle" />
        </h2>
        <p className={css.description}>{lengthLabel}</p>
      </div>
    ) : null;
  };

  return (
    <div>
      {sectionSpaceType}
      {sectionUsage}
      {/* {sectionSizeOfSpace} */}
      {/* {sectionCapacityOfSpace} */}
      {/* {sectionOpeningHours} */}
      {sectionAmenties}
    </div>
  );
};

export default SectionDetailsMaybe;

import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import config from '../../config';

import css from './ListingPage.module.css';
import moment from 'moment';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import { useIntl } from 'react-intl';
const { Money } = sdkTypes;
const SectionBusinessDetailsMaybe = props => {
  const { publicData, intl } = props;

  const {
    businessName,
    yearsOfExperience,
    industry,
    conditions,
    website,
    requestedTiming,
    minimum_spend,
  } = publicData;

  const sectionMinimumSpend = minimum_spend ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.minimumSpendTitle" />
      </h2>
      <p className={css.description}>
        {formatMoney(intl, new Money(minimum_spend, config.currency))}
      </p>
    </div>
  ) : null;
  //section business name
  const sectionBusinessName = businessName ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.businessNameTitle" />
      </h2>
      <p className={css.description}>{businessName}</p>
    </div>
  ) : null;

  //section years of experience
  const sectionYearsOfExperience = yearsOfExperience ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.yearsOfExperienceTitle" />
      </h2>
      <p className={css.description}>{yearsOfExperience}</p>
    </div>
  ) : null;

  //section industry
  const sectionIndustry = industry ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.industryTitle" />
      </h2>
      <p className={css.description}>
        {config.custom.industry?.find(item => item.value == industry)?.label}
      </p>
    </div>
  ) : null;
  //section requested timing
  const sectionRequestedTiming = requestedTiming ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.reuestedTimingTitle" />
      </h2>

      <p className={css.description}>
        Start time : {moment(requestedTiming?.startDate).format('MMMM Do YYYY h:mm:ss a')} <br />
        End time : {moment(requestedTiming?.endDate).format('MMMM Do YYYY h:mm:ss a')}
      </p>
    </div>
  ) : null;

  //section website
  const sectionWebsite = website ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.websiteTitle" />
      </h2>
      <a href={website} target="_blank" className={css.description}>
        {website}
      </a>
    </div>
  ) : null;

  //section conditions
  const sectionConditions =
    conditions?.length > 0 ? (
      <div className={css.sectionDescription}>
        <h2 className={css.descriptionTitle}>
          <FormattedMessage id="ListingPage.conditionsTitle" />
        </h2>
        {conditions.map(item => {
          return <li className={css.description}>{item.condition}</li>;
        })}
      </div>
    ) : null;

  return (
    <div>
      {sectionBusinessName}
      {sectionMinimumSpend}
      {/* {sectionYearsOfExperience} */}
      {/* {sectionIndustry} */}
      {/* {sectionRequestedTiming} */}
      {/* {sectionWebsite} */}
      {sectionConditions}
    </div>
  );
};

export default SectionBusinessDetailsMaybe;

import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { BiCloudDownload } from 'react-icons/bi';
import css from './ListingPage.module.css';
function SectionDocuments(props) {
  const { publicData } = props;
  const { businessPlan, executiveSummary, additionalDocuments } = publicData;
  return businessPlan || executiveSummary || additionalDocuments ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.documentsTitle" />
      </h2>
      <div className={css.documentContainer}>
        {businessPlan && (
          <a href={businessPlan} target="_blank" className={css.documentLinks}>
            <BiCloudDownload className={css.downloadIcon} />{' '}
            <FormattedMessage id="ListingPage.businessPlan" />
          </a>
        )}
        {executiveSummary && (
          <a href={executiveSummary} target="_blank" className={css.documentLinks}>
            <BiCloudDownload className={css.downloadIcon} />{' '}
            <FormattedMessage id="ListingPage.executiveSummary" />
          </a>
        )}
        {additionalDocuments && (
          <a href={additionalDocuments} target="_blank" className={css.documentLinks}>
            <BiCloudDownload className={css.downloadIcon} />{' '}
            <FormattedMessage id="ListingPage.additionalDocuments" />
          </a>
        )}
      </div>
    </div>
  ) : null;
}

export default SectionDocuments;

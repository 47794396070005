import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './SectionTeamMembers.module.css';
import { BsLinkedin } from 'react-icons/bs';
import ReactReadMoreReadLess from 'react-read-more-read-less';

function SectionTeamMembers(props) {
  const { teamMembers, overview } = props;
  // console.log('teamMembers', teamMembers);
  return teamMembers?.length > 0 ? (
    <div>
      <div className={css.container}>
        <h2 className={css.descriptionTitle}>
          <FormattedMessage id="ListingPage.teamTitle" />
        </h2>
        <ReactReadMoreReadLess
          charLimit={100}
          readMoreText={'Read more ▼'}
          readLessText={'Read less ▲'}
          className={css.description}
          readMoreClassName={css.readMore}
          readLessClassName={css.readLess}
        >
          {overview}
        </ReactReadMoreReadLess>
        <div className={css.teamsContainer}>
          {teamMembers.map(member => (
            <div className={css.teamContainer}>
              <div className={css.memberContainer}>
                <div className={css.left}>
                  <img src={member.photo} className={css.teamImage} />
                </div>
                <div className={css.right}>
                  <h3 className={css.memberTitle}>{member.Name}</h3>
                  {/* <h2 className={css.title}>
                  <FormattedMessage id="ListingPage.linkedInTitle" />
                </h2> */}
                  <a href={member.linkedin} target="_blank">
                    <BsLinkedin className={css.icon} />
                  </a>
                  {/* <p className={css.text}>{member.linkedin}</p> */}
                  <h2 className={css.title}>
                    <FormattedMessage id="ListingPage.positionTitle" />
                  </h2>
                  <p className={css.text}>{member.postion}</p>
                </div>
              </div>
              <div className={css.bioContainer}>
                <h2 className={css.title}>
                  <FormattedMessage id="ListingPage.bioTitle" />
                </h2>
                <ReactReadMoreReadLess
                  charLimit={100}
                  readMoreText={'Read more ▼'}
                  readLessText={'Read less ▲'}
                  className={css.bio}
                  readMoreClassName={css.readMore}
                  readLessClassName={css.readLess}
                >
                  {member.bio}
                </ReactReadMoreReadLess>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : null;
}

export default SectionTeamMembers;

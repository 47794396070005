import React from 'react';
import { AvatarLarge, AvatarMedium } from '../../components';

import css from './ListingPage.module.css';

const SectionAvatar = props => {
  const { user, publicData } = props;
  const { logo } = publicData;
  return (
    <div className={css.sectionAvatar}>
      <AvatarLarge
        user={user}
        className={css.avatarDesktop}
        initialsClassName={css.initialsDesktop}
        logo={logo}
        disableProfileLink
      />

      <AvatarMedium user={user} logo={logo} className={css.avatarMobile} disableProfileLink />
    </div>
  );
};

export default SectionAvatar;

import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import config from '../../config';

import css from './ListingPage.module.css';
import SectionTeamMembers from './SectionTeamMembers';
function SectionBusinessShareType(props) {
  const { publicData } = props;
  const {
    idealInvestorRole,
    shortsummary,
    theBusiness,
    theMarket,
    progress_Proof,
    objectives_Future,
    highlights,
    thedeal,
    financials,
    Keywords,
    teamMembers,
    overview,
  } = publicData;
  // console.log('publicData', publicData);
  // console.log('financials', financials);
  const sectionIdealInvestorRole = idealInvestorRole ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.idealInvestorRoleTitle" />
      </h2>
      <p className={css.description}>
        {config.custom.IdealInvestorRole?.find(item => item.key === idealInvestorRole)?.label}
      </p>
    </div>
  ) : null;
  const sectionShortsummary = shortsummary ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.shortsummaryTitle" />
      </h2>
      <p className={css.description}>{shortsummary}</p>
    </div>
  ) : null;
  const sectionTheBusiness = theBusiness ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.theBusinessTitle" />
      </h2>
      <p className={css.description}>{theBusiness}</p>
    </div>
  ) : null;
  const sectionTheMarket = theMarket ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.theMarketTitle" />
      </h2>
      <p className={css.description}>{theMarket}</p>
    </div>
  ) : null;
  const sectionProgressProof = progress_Proof ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.progressProofTitle" />
      </h2>
      <p className={css.description}>{progress_Proof}</p>
    </div>
  ) : null;
  const sectionObjectivesFuture = objectives_Future ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.objectivesFutureTitle" />
      </h2>
      <p className={css.description}>{objectives_Future}</p>
    </div>
  ) : null;
  const sectionHighlights = highlights ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.highlightsTitle" />
      </h2>
      <p className={css.description}>{highlights}</p>
    </div>
  ) : null;
  const sectionThedeal = thedeal ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.thedealTitle" />
      </h2>
      <p className={css.description}>{thedeal}</p>
    </div>
  ) : null;
  const sectionFinancials =
    financials?.length > 0 ? (
      <div className={css.sectionDescription}>
        <h2 className={css.descriptionTitle}>
          <FormattedMessage id="ListingPage.financialsTitle" />
        </h2>
        <table className={css.table}>
          <tr className={css.tr}>
            <th className={css.th}>Years </th>
            <th className={css.th}>Turnover (A$)</th>
            <th className={css.th}>Profit (A$)</th>
          </tr>

          {financials.map((item, index) => (
            <tr key={index} className={css.tr}>
              <td className={css.td}>{item.year}</td>
              <td className={css.td}>{item.turnOver}</td>
              <td className={css.td}>{item.profit}</td>
            </tr>
          ))}
        </table>
      </div>
    ) : null;
  const sectionkeywords =
    Keywords?.length > 0 ? (
      <div className={css.sectionDescription}>
        <h2 className={css.descriptionTitle}>
          <FormattedMessage id="ListingPage.keywordTitle" />
        </h2>
        <div className={css.keywordContainer}>
          {Keywords.map((item, index) => (
            <p className={css.keyword}>{item}</p>
          ))}
        </div>
      </div>
    ) : null;
  return (
    <div>
      {/* {sectionIdealInvestorRole} */}
      {sectionShortsummary}
      {sectionTheBusiness}
      {sectionTheMarket}
      {sectionProgressProof}
      {sectionObjectivesFuture}
      {sectionHighlights}
      {sectionThedeal}
      {sectionFinancials}
      {sectionkeywords}
      <SectionTeamMembers teamMembers={teamMembers} overview={overview} />
    </div>
  );
}

export default SectionBusinessShareType;
